<template>
  <v-container>
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
    <v-expansion-panels :multiple="true" :accordion="false" :value="expanded">
      <v-expansion-panel v-for="item in messagesConfigs" :expand="true" :key="item.id">
        <v-expansion-panel-title>
          <span> {{ item.type + ': ' + item.name }}</span>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row> </v-row>
          <v-data-table-virtual :items="item.messageConfigs" :headers="fields" :sort-by="['messageType', 'channel']" multi-sort>
            <template v-slot:[`item.edit`]="{ item }">
              <div class="link" @click="openDialog(item)">
                <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
              </div>
            </template>
            <template v-slot:[`item.senderAddress`]="{ item }">
              {{ item.senderAddress ? item.senderAddress : '-' }}
            </template>
            <template v-slot:[`item.messageType`]="{ item }">
              {{ getMessageTypeName(item.messageType) }}
            </template>
            <template v-slot:[`item.channel`]="{ item }">
              {{ item.channel ? item.channel.charAt(0) + item.channel.slice(1).toLowerCase() : '' }}
            </template>
            <template v-slot:[`item.lastActivity`]="{ item }">
              <router-link
                :to="{ name: 'messages_sent_read', query: { term: 'messageType=' + item.messageType + '&sendingChannel' + item.channel, size: 50 } }"
                class="tableaction link"
              >
                <v-icon dbmblueprimary>mdi-chevron-right</v-icon></router-link
              >
            </template>
          </v-data-table-virtual>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <!--  Dialog Detail  -->
    <v-dialog :retain-focus="false" max-width="70%" id="configs-detail-dialog" v-model="dialog">
      <v-card>
        <v-card-title>
          <span>{{ getMessageTypeName(configDetails.messageType) }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="detail">
            <v-row>
              <!-- Kanal -->
              <v-col sm="12" md="3" lg="3">
                <v-text-field
                  id="messages_configs_detail_channel"
                  v-model="configDetails.channel"
                  type="text"
                  :label="$t('messages_configs_detail_channel')"
                  disabled
                />
              </v-col>
              <!-- Sender E-Mail / Phone-->
              <v-col sm="12" md="3" lg="3">
                <v-text-field
                  id="messages_configs_detail_sender_address"
                  :disabled="!(configDetails.editable && privileges.has({ path: '/messageConfigs', permission: 'allDomains' }))"
                  v-model.trim="configDetails.senderAddress"
                  :label="$t('messages_configs_detail_sender_address')"
                  :rules="[rules.email, rules.required]"
                />
              </v-col>
              <!-- Sender Name-->
              <v-col sm="12" md="3" lg="3">
                <v-text-field
                  id="messages_configs_detail_sender_name"
                  v-model="configDetails.senderName"
                  :label="$t('messages_configs_detail_sender_name')"
                  disabled
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="6" md="3" lg="3">
                <v-text-field
                  v-if="configDetails.channel !== 'SMS'"
                  :disabled="!configDetails.editable"
                  :label="$t('messages_configs_detail_subject_de')"
                  v-model="configDetails.subjectDe"
                  :placeholder="$t('messages_configs_detail_subject_de')"
                ></v-text-field>
                <v-textarea
                  :disabled="!configDetails.editable"
                  class="custom-message-area"
                  filled
                  rows="10"
                  id="messages_configs_detail_message_de"
                  v-model="configDetails.textDe"
                  :label="$t('messages_configs_detail_message_de')"
                  :rules="[rules.validKey(configDetails.textDe)]"
                ></v-textarea>
              </v-col>
              <v-col sm="6" md="3" lg="3">
                <v-text-field
                  v-if="configDetails.channel !== 'SMS'"
                  :disabled="!configDetails.editable"
                  :label="$t('messages_configs_detail_subject_fr')"
                  v-model="configDetails.subjectFr"
                  :placeholder="$t('messages_configs_detail_subject_fr')"
                ></v-text-field>
                <v-textarea
                  :disabled="!configDetails.editable"
                  filled
                  rows="10"
                  id="messages_configs_detail_message_fr"
                  v-model="configDetails.textFr"
                  :label="$t('messages_configs_detail_message_fr')"
                  :rules="[rules.validKey(configDetails.textFr)]"
                ></v-textarea>
              </v-col>
              <v-col sm="6" md="3" lg="3">
                <v-text-field
                  v-if="configDetails.channel !== 'SMS'"
                  :disabled="!configDetails.editable"
                  :label="$t('messages_configs_detail_subject_it')"
                  v-model="configDetails.subjectIt"
                  :placeholder="$t('messages_configs_detail_subject_it')"
                ></v-text-field>
                <v-textarea
                  :disabled="!configDetails.editable"
                  filled
                  rows="10"
                  id="messages_configs_detail_message_it"
                  v-model="configDetails.textIt"
                  :label="$t('messages_configs_detail_message_it')"
                  :rules="[rules.validKey(configDetails.textIt)]"
                ></v-textarea>
              </v-col>

              <v-col sm="6" md="3" lg="3">
                <v-card class="mx-auto pa-2">
                  <v-data-table-virtual class="mt-0" :items="placeholders" :headers="placeholder_fields" dense :sort-by="['placeholderDescription']">
                    <template v-slot:[`item.placeholderKey`]="{ item }">
                      <v-chip pill class="ma-2" variant="flat" v-html="item.placeholderKey" @click="copyToClipboard(item.placeholderKey)"> </v-chip>
                    </template>
                    <template v-slot:[`item.copy`]="{ item }">
                      <div class="link" @click="copyToClipboard(item.placeholderKey)">
                        <v-icon dbmblueprimary>mdi-content-copy</v-icon>
                      </div>
                    </template>
                    <template v-slot:[`item.placeholderDescription`]="{ item }">
                      <div>
                        {{ $t('messages_configs_detail_placeholder_description_' + item.placeholderKey) }}
                      </div>
                    </template>
                  </v-data-table-virtual>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="configDetails.editable" color="primary" @click="updateConfig(configDetails)" :loading="loadingUpdateConfig">
            <span v-html="$t('messages_subscriptions_detail_save')" />
          </v-btn>
          <v-btn color="secondary" @click="closeDialog">
            <span v-html="$t('messages_subscriptions_detail_cancel')" />
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <!-- History / Remarks-->
          <v-col sm="12">
            <rdhComponent
              :show="true"
              :remarks_show="true"
              :documents_show="false"
              :history_show="true"
              :history_url="'/messageConfigs/' + configDetails.id"
              :remarks_tag="'messageConfigs_' + configDetails.id"
              ref="rdh"
              documents_tag=""
            ></rdhComponent>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'
import { readonly } from 'vue'
import { privileges } from '@/services/privileges'
import rdhComponent from '@/components/rdh/rdhComponent.vue'
import _ from 'lodash'
import { useMessageTypesStore } from '@/store/enums/MessageTypesStore'

export default {
  name: 'messages_configs_read',
  components: { rdhComponent },
  data() {
    return {
      items: [],
      loading: false,
      loadingUpdateConfig: false,
      showDialog: false,
      messagesConfigs: [],
      placeholders: [],
      dialog: false,
      configDetails: {},
      expanded: [],
      privileges: privileges,
      rules: {
        required: (value) => !!value || this.$t('form_field_required'),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !value || pattern.test(value) || this.$t('form_field_invalid_email')
        },
        validKey: (value) => {
          if (value == null) return true
          const pattern = /\$[a-zA-Z0-9_-]*|\$/g
          const matches = value.match(pattern) || []
          const allowedKeys = this.configDetails.placeholders
          const valid = []
          matches.forEach((match) => {
            if (!allowedKeys.includes(match)) {
              valid.push(false)
            } else {
              valid.push(true)
            }
          })
          return valid.every((x) => x === true) || this.$t('form_field_invalid_key')
        }
      },
      fields: [
        {
          value: 'edit',
          sortable: false,
          width: '2%'
        },
        {
          title: this.$t('messages_configs_type'),
          value: 'tbd',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('messages_configs_campaign_group'),
          value: 'messageType', // replace with messageCampaign
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('messages_configs_message_subject'),
          value: 'subject' + this.$route.params.upLang,
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('messages_configs_channel'),
          value: 'channel',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('messages_configs_senderAddress'),
          value: 'senderAddress',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('messages_configs_senderName'),
          value: 'senderName',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('messages_configs_last_activity'),
          value: 'lastActivity',
          sortable: false,
          align: 'false'
        }
      ],
      placeholder_fields: [
        { title: this.$t('messages_configs_detail_placeholder_key'), value: 'placeholderKey', sortable: false, align: 'left', size: '20%' },
        { title: '', value: 'copy', sortable: false, align: 'left', size: '5%' },
        { title: this.$t('messages_configs_detail_placeholder_description'), value: 'placeholderDescription', sortable: false, align: 'left', size: '80%' }
      ]
    }
  },
  methods: {
    readonly,
    async load() {
      this.loading = true
      try {
        const response = await this.axios.get(apiURL + '/messageConfigs/groupedByDomain')
        await response.data
        this.messagesConfigs = response.data
        this.expandAllPanels(this.messagesConfigs)
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    expandAllPanels(panels) {
      this.expanded = panels.map((_, index) => index)
    },
    openDialog(item) {
      this.configDetails = { ...item }
      // workaround to get rid of warning 'item not an Object'
      this.placeholders = this.configDetails.placeholders.map((item) => ({ placeholderKey: item }))
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    async updateConfig(configDetails) {
      this.loadingUpdateConfig = true
      try {
        const result = await this.axios.patch(
          apiURL + '/messageConfigs/' + `${configDetails.id}`,
          {
            ..._.omit(configDetails, ['channel', 'senderName', 'lastActivity', 'campaignGroup', 'placeholders', 'editable'])
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        this.closeDialog()
        emitter.emit('toastSuccess', {
          message: this.$t('toast_save_success_message')
        })
        this.load()
        return result.data
      } catch (e) {
        showError(e)
      } finally {
        this.loadingUpdateConfig = false
      }
    },
    copyToClipboard(placeholder) {
      navigator.clipboard.writeText(placeholder).then(
        () => {
          emitter.emit('toastSuccess', {
            message: this.$t('messages_configs_detail_placeholder_copy_success')
          })
        },
        (err) => {
          emitter.emit('toastError', {
            message: this.$t('messages_configs_detail_placeholder_copy_error', err)
          })
        }
      )
    },
    getMessageTypeName(item) {
      const lang = this.$route.params.upLang
      const messageTypes = useMessageTypesStore().messageTypes
      const messageType = messageTypes.find((messageType) => messageType.name === item)
      return messageType ? messageType[`name${lang}`] : item
    }
  },
  beforeMount() {
    useMessageTypesStore().fetchMessageTypes
  },
  mounted() {
    this.load()
  }
}
</script>

<style lang="scss" scoped>
.v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot {
  margin-right: 0 !important;
}
</style>
